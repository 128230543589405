export const gbbProducts = [
  'free',
  'supporter',
  'good',
  'better',
  'best',
  'mecenas',
];

export const certProducts = [
  'travessia',
  'metodo-bp',
  'russia',
  '12-regras',
  'travessia-familia',
  'cliencia-politica',
  'bitcoin',
];

export const appProducts = ['teller'];
